import Places, { initPlacesByText } from '@tilda/common/Places'

function initPlaces () {
  if (!window.acWidgetPlacesIsInited) {
    const placesNodes: NodeListOf<HTMLDivElement> = document.querySelectorAll('div[data-widget="anyclassPlaces"]')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    placesNodes.forEach(node => new Places({
      nodeElement: node,
      ...node.dataset
    }))
    window.acWidgetPlacesIsInited = true
  }
}

if (!window.acWidgetPlaces) {
  window.acWidgetPlaces = initPlaces
}

if (!window.acPlacesByText) {
  window.acPlacesByText = initPlacesByText
}
