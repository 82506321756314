export interface PlacesPayload extends Record<string, string | number | undefined> {
  tag?: string;
  text: string;
  name?: string;
  min?: number;
  max?: number;
}

const cache: Record<string, string> = {}
export default class Places {
  private nodeElement: HTMLDivElement
  private readonly storeName: string
  private readonly min: number
  private readonly max: number

  constructor ({ nodeElement, name, min = 2, max = 10 }: { nodeElement: HTMLDivElement; name?: string; min: number; max: number }) {
    this.nodeElement = nodeElement
    this.min = Number(min)
    this.max = Number(max)
    const prefixName = name || 'anyclass_places'
    this.storeName = `${prefixName}_${window.location.pathname}_${min}_${max}`

    this.render()
  }

  private getPlaces (): string {
    if (cache[this.storeName]) {
      return cache[this.storeName]
    }
    let count: number | string = Number(localStorage.getItem(this.storeName))
    const random = parseFloat((Math.random() * 0.2 + 0.1).toFixed(2))
    if (Number.isNaN(count) || !count) {
      count = this.max - (this.max * random)
    } else {
      count -= count * random
    }
    count = String(Math.max(Math.round(count), this.min))
    localStorage.setItem(this.storeName, count)
    cache[this.storeName] = count
    return count
  }

  private render () {
    this.nodeElement.innerHTML = this.getPlaces()
  }
}

export function initPlacesByText ({ tag = 'div', text = '{counter}', name, min = 2, max = 10 }: PlacesPayload): void {
  const divNodes = document.querySelectorAll(tag)
  Array.prototype.forEach.call(divNodes, (spanNode) => {
    if (spanNode) {
      Array.prototype.forEach.call(spanNode.childNodes, function (node) {
        if (node.nodeType === 3 && node.nodeValue.indexOf(text) !== -1) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line no-new
          new Places({
            nodeElement: node.parentElement,
            name,
            min,
            max
          })
        }
      })
    }
  })
}
